import React from 'react';
//import exampleImage from '../path-to-your-image.jpg'; // Uncomment and update the path to your image
import frontImg from '../front.png';
export default function LandingPage() {


  return (
    // Add larger vertical padding (py-16 instead of py-8) for bigger margins
    // and use max-w-4xl mx-auto to limit the width and center the content
    <div className="max-w-4xl mx-auto px-4 py-16">
      <div className="flex flex-wrap items-center justify-center md:justify-between">
        {/* Text Section */}
        <div className="w-full md:w-1/2 text-center md:text-left">
          {/* Increase font size for the h1 element, for example, to text-6xl or text-7xl */}
          <h1 className="font-bold mb-6 font-sans text-5xl text-custom-dark">
            Get more from blockchain technology
          </h1>
          <p className="mb-8 font-sans text-3xl text-custom-dark">
            Diya Labs is an R&D hub for everything blockchain.
          </p>
          <a href='/products' className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded font-sans text-lg">
            Our Products
          </a>
        </div>



        {/* Image Section - Adjust margin for better centering if needed */}
        <div className="w-full md:w-1/2 flex justify-center mt-8 md:mt-0">
          <img src={frontImg} alt="Example Illustration" className="max-w-4xl font-sans" />
        </div>
      </div>
    </div>
  );
};
