import React from 'react';

export default function Contact() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow p-6">
        <h1 className="text-3xl font-bold mb-4 font-sans">Get in Touch</h1>
        <p className="mb-4 font-sans">We are happy to answer any inquiries. Please contact via email or phone as seen below.</p>
        
        <div className="space-y-3">
          <div>
            <h2 className="text-xl font-semibold font-sans">Email</h2>
            <p><a href="mailto:victorbrevig@dprotonmail.com" className="text-blue-500 hover:underline font-sans">victorbrevig@protonmail.com</a></p>
          </div>
          
          <div>
            <h2 className="text-xl font-semibold font-sans">Phone Number</h2>
            <p><a href="tel:+4542719930" className="text-blue-500 hover:underline font-sans">+45 42719930</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}
