import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Products from './components/Products';
import About from './components/About';
import Contact from './components/Contact';

function App() {
  return (
    <>
    <div className="bg-gray-100 flex flex-col min-h-screen justify-between">
      <Navbar />
      <Router>
        <Routes>
        <Route exact path='/' element={<LandingPage />} />
        <Route path='/products' element={<Products />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        </Routes>
      </Router>
      <Footer />
    </div>
    
    </>
  );
}

export default App;
