import React from 'react';
// Placeholder image import, replace with your actual image import
import mempoolImg from '../mempool.png';
import wetdotImg from '../WetDot.png';


export default function Products() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow overflow-hidden flex flex-col md:flex-row">
        {/* Product Details */}
        <div className="p-4 md:p-8 md:w-1/2">
          <h2 className="text-3xl font-bold mb-2 font-sans text-custom-dark">WetDot</h2>
          <p className="mb-4 text-xl font-sans text-custom-dark">WetDot by Diya Labs is a protocol for automated smart contract execution. With an easy to use UI, set up triggers in seconds and build trading strategies involving both ERC-20 tokens and NFTs. WetDot is signature based enabling gas less creation of triggers. </p>

          {/* Coming Soon Badge */}
          <div className="py-3 px-5 bg-yellow-500 text-white font-bold rounded-full inline-block mb-4">Coming Soon</div>
          
        </div>

        {/* Image Placeholder */}
        <div className="md:w-1/2 flex items-center justify-center p-4">
          <img src={wetdotImg} alt="Product" className="object-cover h-128 w-full max-w-md" />
        </div>
      </div>
    </div>
  );
}
