import React from 'react';
import victorProfile from '../victor_profile.png';
export default function About() {
  // List of points you want to highlight on the About page

  
  const people = [
    {
      name: 'Victor Brevig',
      role: 'Founder / CEO',
      imageUrl:
        victorProfile,
    },
    // More people...
  ]

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-4 font-sans text-custom-dark">About</h1>
        <p className="mb-8 text-xl font-sans text-gray-600">
          Diya Labs is a Copenhagen based research and development lab. Founded in 2024, our mission is to shine light on all sides of blockchain technology. We accomplish this by building software tools to help users get the most of blockchain data.
        </p>

        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-gray-600">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

    </div>
    
  );
}
