import whiteLogo from '../white_logo.png';

export default function Navbar() {
  return (
    // Add sticky top-0 to make the navbar stick to the top of the viewport
    <nav className="bg-custom-dark p-4 rounded-lg shadow mx-4 my-2 sticky top-0 z-50">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
        <a href="/" className="flex items-center">
            <img src={whiteLogo} alt="Diya Labs" className="h-12" /> {/* Adjust the class as needed */}
          </a>
          <div className="hidden md:flex space-x-4">
            <a href="/" className="text-white hover:text-gray-300 font-sans">Home</a>
            <a href="/products" className="text-white hover:text-gray-300 font-sans">Products</a>
            <a href="/about" className="text-white hover:text-gray-300 font-sans">About</a>
            <a href="/contact" className="text-white hover:text-gray-300 font-sans">Contact</a>
          </div>
        </div>
      </div>
    </nav>
  );
}
